import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './css/font.css';
import '@/css/reset.scss'
import '@/css/scss.scss'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);

new Vue({
	router,
	render: h => h(App)
}).$mount('#app')
