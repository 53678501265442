<template>
	<div id="app">
		<router-view/>
	</div>
</template>

<script>
  export default {
    data(){
        return {
			
        }
    },
    mounted(e){
        
    }
  }
</script>

<style lang="scss"></style>
